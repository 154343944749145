import { Business } from '@mui/icons-material';
import { BusinessRuleId } from 'data/BusinessRuleId';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';

const useGetFilterData = () => {
    const getFormattedProducingArea = (producingArea: any, callBack: any) => {
        let formatedProducingArea = producingArea.map((item) => {
            return { value: item.ProducingAreaCode || '', label: `${item.ProducingAreaCode || ''} - ${item.ProducingAreaName || ''}` };
        });
        formatedProducingArea = [...new Map(formatedProducingArea.map((item) => [item['value'], item])).values()];
        callBack((prev) => {
            return {
                ...prev,
                producingAreaList: formatedProducingArea
            };
        });
    };

    const getFormattedGrowers = (grower: any, callBack: any) => {
        let formatedGrower = grower.map((item) => {
            return {
                value: item.GrowerNbr || '',
                label: `${item.GrowerNbr || ''} - ${item.GrowerName || ''}`,
                producingarea: item.ProducingAreaCode
            };
        });
        formatedGrower = [...new Map(formatedGrower.map((item) => [item['value'], item])).values()];
        callBack((prev) => {
            return {
                ...prev,
                growerList: formatedGrower
            };
        });
    };

    const getFormattedRanches = (ranch, callBack, ruleDetails, createRowDetails) => {
        const producingAreaCodes = [...new Set(createRowDetails?.producingAreaSelectedList?.map((item) => item.ProducingAreaCode))];
        const growerNbrs = [...new Set(createRowDetails?.growerSelectedList?.map((item) => item.GrowerNbr))];
        const berryTypes = createRowDetails ? [...new Set(createRowDetails?.berryTypeSelectedList?.map((item) => item.BerryType))] : [];
        const fieldTypes = createRowDetails ? [...new Set(createRowDetails?.fieldTypeSelectedList?.map((item) => item.FieldType))] : [];
        let formatedRanch = ranch
            .filter((item) => {
                return (
                    (producingAreaCodes.length > 0 ? producingAreaCodes?.includes(item.ProducingAreaCode) : true) &&
                    (growerNbrs.length > 0 ? growerNbrs?.includes(item.GrowerNbr) : true) &&
                    (fieldTypes.length > 0 ? fieldTypes?.includes(item.FieldType) : true) &&
                    (berryTypes.length > 0 ? berryTypes?.includes(item.BerryType) : true)
                );
            })
            .map((item) => {
                return { value: item.RanchNbr || '', label: `${item.RanchNbr || ''} - ${item.RanchName || ''}` };
            });
        formatedRanch = [...new Map(formatedRanch.map((item) => [item['value'], item])).values()];
        callBack((prev) => {
            return {
                ...prev,
                ranchList: formatedRanch
            };
        });
    };

    const getFormattedItems = (item, callBack, createRowDetails) => {
        const berryTypes = createRowDetails ? [...new Set(createRowDetails?.berryTypeSelectedList?.map((item) => item.value))] : [];
        const brands = createRowDetails ? [...new Set(createRowDetails?.brandSelectedList?.map((item) => item.value))] : [];
        const fieldTypes = createRowDetails ? [...new Set(createRowDetails?.fieldTypeSelectedList?.map((item) => item.value))] : [];
        let formatedItem = item
            .filter((item) => {
                return (
                    (brands.length ? brands?.includes(item.Brand) : true) &&
                    (berryTypes.length > 0 ? berryTypes?.includes(item.BerryType) : true) &&
                    (fieldTypes.length > 0 ? fieldTypes?.includes(item.FieldType) : true)
                );
            })
            .map((item) => {
                return { value: item.ItemNbr || '', label: `${item.ItemNbr || ''} - ${item.ItemName || ''}` };
            });

        formatedItem = [...new Map(formatedItem.map((item) => [item['value'], item])).values()];
        callBack((prev) => {
            return {
                ...prev,
                itemList: formatedItem
            };
        });
    };

    const getFormatedBusinessRuleCardFields = (details: any, isEditMode: boolean, defaultFields: any) => {
        let updatedFields = {
            ...defaultFields,
            ruleId: { ...defaultFields.ruleId, value: details.RuleId, disabled: true, visible: isEditMode ? true : false },
            description: { ...defaultFields.description, value: details.RuleName },
            startDate: { ...defaultFields.startDate, value: details.StartDate ? DuDateUtilities.FormatDateFromIso(details.StartDate) : null },
            endDate: { ...defaultFields.endDate, value: details.EndDate ? DuDateUtilities.FormatDateFromIso(details.EndDate) : null },
            status: { ...defaultFields.status, value: isEditMode ? details.Status : 'NEW', disabled: true },
            createdBy: { ...defaultFields.createdBy, visible: isEditMode, value: details.CreatedBy, disabled: true },
            createdDateTime: {
                ...defaultFields.createdDateTime,
                visible: isEditMode,
                value: DuDateUtilities.FormatDateFromIso(details.CreatedDateTime, ''),
                disabled: true
            },
            modifiedBy: { ...defaultFields.modifiedBy, visible: isEditMode, value: details.ModifiedBy, disabled: true },
            modifiedDateTime: {
                ...defaultFields.modifiedDateTime,
                visible: isEditMode,
                value: DuDateUtilities.FormatDateFromIso(details.ModifiedDateTime, ''),
                disabled: true
            },
            approver: { ...defaultFields.approver, visible: isEditMode, value: details.Approver, disabled: true },
            approvedDateTime: {
                ...defaultFields.approvedDateTime,
                visible: isEditMode,
                value: DuDateUtilities.FormatDateFromIso(details.ApprovedDateTime, ''),
                disabled: true
            },
            //TODO: After API has data:  rejectReason: {...defaultFields.rejectReason, value: details.rejectReason, visible: details.Status === 'Rejected' ? true : false, disabled: true}
            rejectReason: { ...defaultFields.rejectReason, value: details.rejectReason, visible: false, disabled: true }
        };
        switch (details.RuleTypeId) {
            // For Pricing - RuleTypeId is 1
            case BusinessRuleId.PRICING_POOL_ID:
                updatedFields = {
                    ...updatedFields,
                    defaultPrice: { ...defaultFields.defaultPrice, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For Packaging Deduction - RuleTypeId is 2
            case BusinessRuleId.PACKAGING_DEDUCTION_ID:
                updatedFields = {
                    ...updatedFields,
                    defaultPackagingCost: { ...defaultFields.defaultPackagingCost, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For Packaging Credit - RuleTypeId is 5
            case BusinessRuleId.PACKAGING_CREDIT_ID:
                updatedFields = {
                    ...updatedFields,
                    defaultPackagingCost: { ...defaultFields.defaultPackagingCost, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For PFQ - RuleTypeId is 4
            case BusinessRuleId.SETTLEMENT_POOL_ID:
                updatedFields = {
                    ...updatedFields,
                    defaultRelativeScore: { ...defaultFields.defaultRelativeScore, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    pfq: { ...defaultFields.pfq, value: details?.PFQFlag },
                    packagingPool: { ...defaultFields.packagingPool, value: details?.PricingPoolId }
                };
                return updatedFields;
            // For Driscolls Share - RuleTypeId is 3
            case BusinessRuleId.DRISCOLLS_SHARE_ID:
                updatedFields = {
                    ...updatedFields,
                    ruleId: { ...defaultFields.ruleId, value: details.RuleId, disabled: true },
                    sharePercent: { ...defaultFields.sharePercent, value: details?.Formula ? details?.Formula[0].FormulaValue : '' }
                };
                return updatedFields;
            // For Grower Finance/ Crop Investment - RuleTypeId is 6
            case BusinessRuleId.GROWER_FINANCE_ID:
                updatedFields = {
                    ...updatedFields,
                    harvestCost: { ...defaultFields.harvestCost, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    financePercent: { ...defaultFields.financePercent, value: details?.Formula ? details?.Formula[1].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For HOMI Premium - RuleTypeId is 7
            case BusinessRuleId.HOMI_ID:
                updatedFields = {
                    ...updatedFields,
                    ruleValue: { ...defaultFields.ruleValue, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For Advance Payment - RuleTypeId is 8
            case BusinessRuleId.ADVANCE_PAYMENT_ID:
                updatedFields = {
                    ...updatedFields,
                    advanceAmount: { ...defaultFields.advanceAmount, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For Plant Recovery - RuleTypeId is 9
            case BusinessRuleId.PLANT_RECOVERY_ID:
                updatedFields = {
                    ...updatedFields,
                    ruleValue: { ...defaultFields.ruleValue, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For Fresh Credit - RuleTypeId is 10
            case BusinessRuleId.FRESH_CREDIT_ID:
                updatedFields = {
                    ...updatedFields,
                    ruleValue: { ...defaultFields.ruleValue, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For Assessment - RuleTypeId is 11
            case BusinessRuleId.ASSESSMENT_ID:
                updatedFields = {
                    ...updatedFields,
                    ruleValue: { ...defaultFields.ruleValue, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For Packaging Issuance - RuleTypeId is 12
            case BusinessRuleId.PACKAGING_ISSUANCE_ID:
                updatedFields = {
                    ...updatedFields,
                    defaultPackagingCost: { ...defaultFields.defaultPackagingCost, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For HOMI Deduction - RuleTypeId is 13
            case BusinessRuleId.HOMI_DEDUCTION_ID:
                updatedFields = {
                    ...updatedFields,
                    ruleValue: { ...defaultFields.ruleValue, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For Advance Reversal - RuleTypeId is 14
            case BusinessRuleId.ADVANCE_REVERSAL:
                updatedFields = {
                    ...updatedFields,
                    defaultPrice: { ...defaultFields.defaultPrice, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For PFQ Adjustments - RuleTypeId is 15
            case BusinessRuleId.PFQ_ADJUSTMENT_ID:
                updatedFields = {
                    ...updatedFields,
                    defaultPrice: { ...defaultFields.defaultPrice, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For Adjustment Premium - RuleTypeId is 16
            case BusinessRuleId.ADJUSTMENT_PREMIUM_ID:
                updatedFields = {
                    ...updatedFields,
                    defaultPrice: { ...defaultFields.defaultPrice, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM },
                    rateType: { ...defaultFields.rateType, value: details?.Formula ? details?.Formula[0].FormulaType : '' }
                };
                return updatedFields;
            // For Adjustment Premium - RuleTypeId is 17
            case BusinessRuleId.ADJUSTMENT_DEDUCTION_ID:
                updatedFields = {
                    ...updatedFields,
                    defaultPrice: { ...defaultFields.defaultPrice, value: details?.Formula ? details?.Formula[0].FormulaValue : '' },
                    uom: { ...defaultFields.uom, value: details.UOM }
                };
                return updatedFields;
            // For default
            default:
                updatedFields = {
                    ...updatedFields,
                    ruleValue: { ...defaultFields.ruleValue, value: details?.Formula ? details?.Formula[0].FormulaValue : '' }
                };
                return updatedFields;
        }
    };

    return { getFormattedProducingArea, getFormattedGrowers, getFormattedRanches, getFormattedItems, getFormatedBusinessRuleCardFields };
};
export default useGetFilterData;
