import { OverviewPage, StatusWrapper, syncTranslations } from '@driscollsinc/ggs-workbench-module-ui';
import { Breadcrumbs, Typography, useColorScheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { withOktaAuth } from '@okta/okta-react';
import { setPageTitleAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import { getRoute, ROUTES } from 'constants/routes';
import { SettlementActions } from 'constants/SettlementActions';
import { SettlementEngineStatus } from 'constants/SettlementEngineStatus';
import { itemAssignmentColumns } from 'data/BusinessRuleHistoryData';
import { designColors } from 'data/constants';
import MasterDataUtilities from 'data/MasterDataUtilities';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';
import useDashboard from 'hooks/v2/useDashboard';
import useGetApiTokens from 'hooks/v2/useGetApiTokens';
import useLang from 'hooks/v2/useLang';
import useSettlement from 'hooks/v2/useSettlement';
import useSettlementMasterData from 'hooks/v2/useSettlementMasterData';
import { NAMESPACE } from 'i18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { withRouter } from 'routes/withRouter';
import DuExcelUtilities from 'utils/excelUtility';

const getUniqueListBy = (arr: any, key: string) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
};

const DEFAULT_FLAGS = {
    post: true,
    submit: true,
    publish: true,
    process: true
};

function WorkbenchOverView(props) {
    const {
        getEntries,
        getFilterDropdownValues,
        getDetailHeaders,
        getSettlementEngine,
        getSettlementEngineStatus,
        sendSettlementMail,
        getApprovers,
        getGluePayLoad,
        GLUE_JOB_PUBLISH_PDF,
        approverList,
        publishToOracle,
        post2Oracle,
        getPoolWeekSettlementStatus,
        poolweekSettlementStatus,
        poolweekStatusProgrss
    } = useSettlement(props.oktaAuth);
    const { getEligiblePoolweek } = useDashboard(props.oktaAuth);
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [params] = useSearchParams();
    const [status, setStatus] = useState(SettlementEngineStatus.NEW);
    const [advancePaymentPoolWeek, setAdvancePaymentPoolWeek] = useState(SettlementEngineStatus.NEW);
    const [statusInfo, setStatusInfo] = useState({
        SubmittedBy: '',
        CreatedDateTime: '',
        Comments: '',
        ApprovedDateTime: '',
        ApprovedBy: '',
        SubmittedDateTime: '',
        CreatedBy: '',
        ModifiedDateTime: '',
        ModifiedBy: ''
    });
    const [flags, setFlags] = useState(DEFAULT_FLAGS);
    const { getTextTranslated } = useLang();
    const { getTokenAndId } = useGetApiTokens();
    const [entryColumns, setEntryColumns] = useState<string[]>([]);
    const [hasData, setHasData] = useState(false);
    const [assingableList, setAssignableList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [filters, setFilters] = useState<{ [key: string]: { label: string; value: string } }>({});
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [poolWeek, setPoolWeek] = useState(params.get('poolWeek'));

    useEffect(() => {
        setLoadingOptions(true);
        getFilterDropdownValues(poolWeek, '', '', '', '', '')
            .then((data) => {
                setLoadingOptions(false);
                return setAssignableList(data ?? []);
            })
            .catch(() => setLoadingOptions(false));
    }, [poolWeek]);

    const checkPoolWeekRunstatus = () => {
        getSettlementEngineStatus(params.get('poolWeek')).then((status) => {
            setStatusInfo({
                SubmittedBy: status?.SubmittedBy ?? '',
                CreatedDateTime: status?.CreatedDateTime ?? '',
                Comments: status?.Comments ?? '',
                ApprovedDateTime: status?.ApprovedDateTime,
                ApprovedBy: status?.ApprovedBy,
                SubmittedDateTime: status?.SubmittedDateTime,
                CreatedBy: status?.CreatedBy,
                ModifiedDateTime: status?.ModifiedDateTime,
                ModifiedBy: status?.ModifiedBy
            });
            switch (status?.Status?.toUpperCase() ?? 'NEW') {
                case SettlementEngineStatus.NEW:
                case SettlementEngineStatus.OPEN:
                case SettlementEngineStatus.REJECTED:
                case SettlementEngineStatus.FAILED:
                    {
                        setFlags({
                            publish: true,
                            post: true,
                            submit: true,
                            process: false
                        });
                        setStatus(status?.Status?.toUpperCase() ?? 'NEW');
                    }
                    break;
                case SettlementEngineStatus.PROCESSED:
                    {
                        setFlags({
                            publish: true,
                            post: true,
                            submit: !hasData || false,
                            process: false
                        });
                        setStatus(SettlementEngineStatus.PROCESSED);
                    }
                    break;
                case SettlementEngineStatus.APPROVED:
                    {
                        setFlags({
                            publish: true,
                            post: !hasData || false,
                            submit: true,
                            process: true
                        });
                        setStatus(SettlementEngineStatus.APPROVED);
                    }
                    break;
                case SettlementEngineStatus.POSTED_TO_ORACLE: {
                    setFlags({
                        publish: !hasData || false,
                        post: true,
                        submit: true,
                        process: true
                    });
                    setStatus(SettlementEngineStatus.POSTED_TO_ORACLE);
                    break;
                }
                default:
                    setFlags(DEFAULT_FLAGS);
                    setStatus(status?.Status);
            }
        });
    };

    const getEligblePoolweek = async () => {
        const data = await getEligiblePoolweek({ poolweek: params.get('poolWeek') });
        setAdvancePaymentPoolWeek(data?.AdvancePaymentPoolWeek);
    };

    useEffect(() => {
        checkPoolWeekRunstatus();
        getApprovers();
        if (params.get('poolWeek')) {
            getEligblePoolweek();
        }
    }, [params]);

    const pagesize = 1000;
    const startpage = 1;
    const ranchPagesize = 3500;

    useEffect(() => {
        checkPoolWeekRunstatus();
    }, [hasData]);

    useEffect(() => {
        syncTranslations(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        setFilteredList(() => {
            let newFilteredList = assingableList;
            if (filters.producingArea) newFilteredList = newFilteredList.filter((item) => filters.producingArea.value === item.ProducingAreaCode);
            if (filters.grower) newFilteredList = newFilteredList.filter((item) => filters.grower.value === item.GrowerNbr);
            if (filters.ranch) newFilteredList = newFilteredList.filter((item) => filters.ranch.value === item.RanchNbr);
            if (filters.item) newFilteredList = newFilteredList.filter((item) => filters.item.value === item.ItemNbr);
            return newFilteredList;
        });
    }, [filters, assingableList]);

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }
    if (props.userIsAdmin) {
        return <Navigate to={getRoute(ROUTES.SETTLEMENT_APPROVE)} />;
    }

    const exportAsExcel = (rows) => {
        const curPoolWeek = props.currentPoolWeek.length ? props.currentPoolWeek[0].PoolWeek : 0;
        const excelColumns = entryColumns.map((col: string) => ({
            key: col,
            name: col
        }));
        DuExcelUtilities.Write('GGS-Settlement_PW' + curPoolWeek + '_Data.xlsx', excelColumns, rows);
    };

    const onInitPoolWeekStatusFetch = () => {
        getPoolWeekSettlementStatus(
            props.yearPoolWeeks.map((yr) => ({
                week: yr.PoolWeek,
                startDate: yr.PoolWeekStartDate,
                endDate: yr.PoolWeekEndDate
            }))
        );
    };

    return (
        <DrcWrapper>
            <div style={{ padding: '0px 20px', height: '100%' }}>
                <OverviewPage
                    breadcrumbs={
                        <Breadcrumbs sx={{ color: 'black', '@media (prefers-color-scheme: dark)': { color: `#a7a7a7 !important` } }}>
                            <Typography sx={{ color: designColors.blue, '@media (prefers-color-scheme: dark)': { color: 'white' } }} fontWeight={500}>
                                Home
                            </Typography>
                            <Typography>Workspace</Typography>
                        </Breadcrumbs>
                    }
                    onInitPoolWeekStatusFetch={onInitPoolWeekStatusFetch}
                    poolweekSettlementStatus={poolweekSettlementStatus}
                    poolweekStatusProgrss={poolweekStatusProgrss}
                    onExport={(data) => exportAsExcel({ data: data })}
                    refreshStatus={() => checkPoolWeekRunstatus()}
                    defaultWeek={props.currentPoolWeek.length ? props.currentPoolWeek[0].PoolWeek : 0}
                    availablePoolWeeks={props.yearPoolWeeks.map((yr) => ({
                        week: yr.PoolWeek,
                        startDate: yr.PoolWeekStartDate,
                        endDate: yr.PoolWeekEndDate
                    }))}
                    customHeaderRender={{
                        Status: (data) => (
                            <StatusWrapper
                                title={data.DataValue}
                                status={status}
                                data={{
                                    ...statusInfo,
                                    CreatedDateTime: statusInfo.CreatedDateTime
                                        ? DuDateUtilities.Convert(new Date(statusInfo.CreatedDateTime), { convertToPretty: true })
                                        : ''
                                }}
                            />
                        )
                    }}
                    fetchHeaders={async (filters) => getDetailHeaders(filters.poolWeek)}
                    fetchEntries={async (page, filters, sorting) => {
                        setHasData(false);
                        const entries = await getEntries({
                            PoolWeek: filters.poolWeek,
                            ProducingAreaCode: filters.producingArea?.value,
                            RanchNbr: filters.ranch?.value,
                            GrowerNbr: filters.grower?.value,
                            ItemNbr: filters.item?.value
                        });

                        if (entries.length > 0) {
                            let firstEntry = entries[0];
                            setEntryColumns(Object.keys(firstEntry));
                            setHasData(true);
                        }
                        setPoolWeek(filters.poolWeek);

                        return { data: entries, totalItems: entries.length };
                    }}
                    onRowClick={(rowData) => {
                        navigate(`Details?PFQPool=${rowData.PFQPool}&PricingPool=${rowData.PricingPool}&poolWeek=${rowData.PoolWeek}`);
                    }}
                    entryColumns={entryColumns.map((key) => ({ header: getTextTranslated(key, NAMESPACE.SETTLEMENT), accessorKey: key, size: 200 }))}
                    options={{
                        producingArea: getUniqueListBy(
                            filteredList.map((p) => ({
                                label: `${p.ProducingAreaCode}-${p.ProducingAreaName}`,
                                value: p.ProducingAreaCode
                            })),
                            'value'
                        ),
                        grower: getUniqueListBy(
                            filteredList.map((g) => ({ label: `${g.GrowerNbr}-${g.GrowerName}`, value: g.GrowerNbr })),
                            'value'
                        ),
                        ranch: getUniqueListBy(
                            filteredList.map((r) => ({ label: `${r.RanchNbr}-${r.RanchName}`, value: r.RanchNbr })),
                            'value'
                        ),
                        item: getUniqueListBy(
                            filteredList.map((r) => ({ label: `${r.ItemNbr}-${r.ItemName}`, value: r.ItemNbr })),
                            'value'
                        )
                    }}
                    processFlags={flags}
                    onProcess={async (poolWeek) => {
                        const response = await getSettlementEngine(poolWeek, advancePaymentPoolWeek);
                        const isSuccess = response.display?.Status?.toLowerCase() !== 'failed';
                        await checkPoolWeekRunstatus();
                        if (!isSuccess) throw Error(response.display.Message || getTextTranslated('SettlementRunFailure', NAMESPACE.SETTLEMENT));
                        props.showToast(
                            response.display.Message ||
                                (isSuccess
                                    ? getTextTranslated('SettlementRunSuccess', NAMESPACE.SETTLEMENT)
                                    : getTextTranslated('SettlementRunFailure', NAMESPACE.SETTLEMENT)),
                            isSuccess
                        );
                    }}
                    onSubmit={async (poolWeek) => {
                        const { userEmail } = await getTokenAndId(props.oktaAuth);
                        const response = await sendSettlementMail({
                            Action: 'REQUEST4APPROVAL',
                            PoolWeek: poolWeek,
                            WebUrl: '/SettlementWorkbench/ApprovePoolWeek',
                            SubmittedBy: userEmail,
                            SubmittedDateTime: DuDateUtilities.ToIsoDate(new Date())
                        });
                        const isSuccess = response.data?.Status !== -1;
                        props.showToast(
                            response.data.Message ||
                                (isSuccess
                                    ? getTextTranslated('SettlementSubmitSuccess', NAMESPACE.SETTLEMENT)
                                    : getTextTranslated('SettlementSubmitFailure', NAMESPACE.SETTLEMENT)),
                            isSuccess
                        );
                    }}
                    onPublish={async (poolWeek) => {
                        const { userEmail } = await getTokenAndId(props.oktaAuth);
                        const response = await publishToOracle(
                            {
                                GlueRequest: getGluePayLoad(GLUE_JOB_PUBLISH_PDF, poolWeek, null, userEmail),
                                Action: SettlementActions.PUBLISH,
                                LoginId: userEmail,
                                PoolWeek: poolWeek,
                                WebUrl: 'Settlement',
                                SubmittedBy: userEmail,
                                SubmittedDateTime: DuDateUtilities.ToIsoDate(new Date())
                            },
                            poolWeek,
                            advancePaymentPoolWeek
                        );
                        const isSuccess = response.data?.Status !== -1;
                        props.showToast(
                            response.data.Message ||
                                (isSuccess
                                    ? getTextTranslated('SettlementSubmitSuccess', NAMESPACE.SETTLEMENT)
                                    : getTextTranslated('SettlementSubmitFailure', NAMESPACE.SETTLEMENT)),
                            isSuccess
                        );
                    }}
                    onPost={async (poolWeek) => {
                        const { userEmail } = await getTokenAndId(props.oktaAuth);
                        const response = await post2Oracle(
                            {
                                Action: SettlementActions.POST2ORACLE,
                                LoginId: userEmail,
                                PoolWeek: poolWeek,
                                WebUrl: 'Settlement',
                                SubmittedBy: userEmail,
                                SubmittedDateTime: DuDateUtilities.ToIsoDate(new Date())
                            },
                            poolWeek
                        );
                        const isSuccess = response.display?.Status !== -1;
                        props.showToast(
                            response.display.Message ||
                                (isSuccess
                                    ? getTextTranslated('SettlementSubmitSuccess', NAMESPACE.SETTLEMENT)
                                    : getTextTranslated('SettlementSubmitFailure', NAMESPACE.SETTLEMENT)),
                            isSuccess
                        );
                    }}
                    onError={(message) => props.showToast(message)}
                    approverList={approverList.map((a) => a.label)}
                    onTableFilterChange={(filters) => {
                        setFilters(filters);
                    }}
                />
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ masterReducer, settlementWorkbenchReducer, rootReducer }) => ({
    userIsAdmin: rootReducer.userIsAdmin,
    isMasterDataInitialized: masterReducer.isInitialized,
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    currentPoolWeek: masterReducer.currentPoolWeek,
    yearPoolWeeks: masterReducer.yearPoolWeeks,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(WorkbenchOverView)));
