export const BusinessRuleId = {
    PRICING_POOL_ID: 1,
    PACKAGING_DEDUCTION_ID: 2,
    DRISCOLLS_SHARE_ID: 3,
    ADJUSTMENT_DEDUCTION_ID: 17,
    ADJUSTMENT_PREMIUM_ID: 16,
    PFQ_ADJUSTMENT_ID: 15,
    ADVANCE_REVERSAL: 14,
    HOMI_DEDUCTION_ID: 13,
    PACKAGING_ISSUANCE_ID: 12,
    ASSESSMENT_ID: 11,
    FRESH_CREDIT_ID: 10,
    PLANT_RECOVERY_ID: 9,
    ADVANCE_PAYMENT_ID: 8,
    HOMI_ID: 7,
    GROWER_FINANCE_ID: 6,
    PACKAGING_CREDIT_ID: 5,
    SETTLEMENT_POOL_ID: 4
};
